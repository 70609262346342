import { useEffect } from 'react';

const CosmeticsApp = () => {
  useEffect(() => {
    window.location.reload();
  }, []);

  return null;
};

export default CosmeticsApp;